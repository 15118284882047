<template>
  <section v-editable="blok" cta-banner :style="[sectionStyles]">
    <div class="container">
      <div class="section-row">
        <div class="section-content">
          <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
          <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
          <div v-if="blok.buttons" class="section-buttons">
            <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/cta-banner.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));

const sectionStyles = computed(() => {
  return {
    "background-image": `url(${props.blok?.background?.filename})`,
  };
});
</script>
